import React, { memo, useState } from "react"
import isEqual from "lodash/isEqual"
import type { LayoutType } from "@lesmills/gatsby-theme-common"
import {
  formatDateTime,
  DATETIME_FORMAT,
  CURRENCIES,
  Table,
} from "@lesmills/gatsby-theme-common"
import {
  sortInvoices,
  DEFAULT_SORTING_TYPE,
  SORTING_ICON,
} from "../../utils/invoices"

type Props = {|
  prismicContent: Object,
  layout: LayoutType,
  invoices: Array<Object>,
|}

const PaymentHistoryTable = ({ prismicContent, layout, invoices }: Props) => {
  const [data, setData] = useState({
    invoices,
    sorting_type: DEFAULT_SORTING_TYPE,
  })

  const { body = [] } = prismicContent
  const [table = {}] = body

  const columnStyles = {
    date: {
      classNames: "md:w-16/100 w-full text-left mb-20 md:mb-0",
    },
    subscription: {
      classNames: "text-left md:w-1/4 w-full mb-20 md:mb-0",
    },
    service_period: {
      classNames: "text-left md:w-43/100 w-full mb-10 md:mb-0",
    },
    total: {
      classNames: "md:text-right md:w-16/100 absolute md:static right-0",
    },
  }

  const headers = table.items
    ? table.items.map(({ column_id, column_name }) => {
        const currentSortType = data.sorting_type
        return {
          id: column_id,
          ...columnStyles[column_id],
          name: (
            <p>
              {column_name.text}
              {column_id === "date" && (
                <i
                  className={`fa ${SORTING_ICON[currentSortType]} pl-8 cursor-pointer`}
                  onClick={() => toggleSorting(currentSortType)}
                />
              )}
            </p>
          ),
        }
      })
    : []

  const toggleSorting = currentType => {
    const sorting =
      currentType === DEFAULT_SORTING_TYPE ? "asc" : DEFAULT_SORTING_TYPE

    return setData({
      invoices: sortInvoices(data.invoices, sorting),
      sorting_type: sorting,
    })
  }

  const rows = () =>
    data.invoices.map(item => {
      const currency = CURRENCIES[item.currency]
      return {
        date: {
          customCell: (
            <a
              href={item.public_url}
              className="underline"
              target="_blank"
              rel="noopener"
            >
              {formatDateTime(item.issue_date, DATETIME_FORMAT.default, layout)}
            </a>
          ),
        },
        subscription: {
          value: item.product_name,
          classNames: "md:w-60/100 lg-w-full",
        },
        service_period: {
          value:
            formatDateTime(
              item.period_range_start,
              DATETIME_FORMAT.default,
              layout
            ) +
            "-" +
            formatDateTime(
              item.period_range_end,
              DATETIME_FORMAT.default,
              layout
            ),
          classNames: "md:w-70/100 lg:w-full",
        },
        total: {
          value: `${currency}${parseFloat(item.total_amount).toFixed(2)}`,
          classNames: "md:font-base-light font-base-black",
        },
      }
    })

  return (
    <Table
      headers={headers}
      rows={rows()}
      rowClassNames=" relative md:static"
    />
  )
}
export default memo(PaymentHistoryTable, (prevProps, nextProps) => {
  return (
    isEqual(prevProps.prismicContent, nextProps.prismicContent) &&
    isEqual(prevProps.invoices, nextProps.invoices)
  )
})
